import React from "react";
import * as styles from "./styles.module.scss";
import Footer from "../Footer/Footer";
import NavBar from "./NavBar";
import { scrollToTop } from "utils";
import NewArticleBanner from "components/NewArticleBanner";
import { getCookie } from "utils";

const Layout = ({ children, path, ...rest }) => {
  const bannerTitleRef = React.useRef(null);
  const bannerCookieObject = JSON.parse(getCookie("banner_viewed") || "{}");
  const cookieTitle = bannerCookieObject.title;

  if (Object.keys(children.props.pageContext).length) {
    if (children?.props.pageContext.post) {
      bannerTitleRef.current = children?.props.pageContext.post.title;
    }
  }

  const shouldRenderNewArticleBanner =
    (path === "/" && !bannerCookieObject.banner_viewed) ||
    (path === "/" &&
      cookieTitle &&
      bannerTitleRef.current !== null &&
      cookieTitle !== bannerTitleRef.current);

  return (
    <div className={styles.wrapper}>
      {!!shouldRenderNewArticleBanner && <NewArticleBanner />}
      <div className={styles.navWrapper}>
        <NavBar />
      </div>
      <div className={styles.container}>{children}</div>
      <Footer />
      <BackToTopButton />
    </div>
  );
};

function BackToTopButton() {
  const ref = React.useRef(null);

  function onBackToTopClick() {
    scrollToTop();
  }

  function scrollListener() {
    if (!ref.current) return;

    let button = ref.current;
    let scrollTop = document.documentElement.scrollTop;
    let opacity = 0;
    const threshold = 200;

    if (scrollTop >= threshold) {
      opacity += opacity + (scrollTop - threshold) / 100;
    }
    if (opacity >= 0) {
      button.style.opacity = opacity;
    }
  }

  React.useEffect(() => {
    window.addEventListener("scroll", scrollListener);

    return () => window.removeEventListener("scroll", scrollListener);
  }, []);

  return (
    <div ref={ref} className={styles.backToTopButton}>
      <button onClick={onBackToTopClick}>
        <span>&#8963;</span>
      </button>
    </div>
  );
}

export default Layout;
