import React, { useState, useEffect, ReactElement } from "react";
import * as styles from "./NewArticleBanner.module.scss";
import { graphql, useStaticQuery, Link } from "gatsby";
import { getCookie, setCookie } from "utils";
import track from "utils/analytic";
const BANNER_VIEWED_COOKIE = "banner_viewed";

/**
 * @returns {ReactElement}
 */
const NewArticleBanner = () => {
  const [isClicked, setIsClicked] = useState(false);
  const data = useStaticQuery(graphql`
    query {
      allWpPost {
        edges {
          node {
            slug
            title
          }
        }
      }
    }
  `);

  const node = data.allWpPost?.edges?.[0]?.node;

  useEffect(() => {
    if (isClicked) {
      if (!getCookie(BANNER_VIEWED_COOKIE)) {
        const bannerViewedCookie = {
          banner_viewed: 1,
          title: node.title,
        };
        setCookie(BANNER_VIEWED_COOKIE, JSON.stringify(bannerViewedCookie), 14);
      }
    }
  }, [isClicked]);

  const { slug, title } = node || {};

  const uri = `/insights/${slug}`;

  if (!title || !slug) return null;
  return (
    <div className={styles.container} id="new-article-banner">
      <strong>Read Latest Article:</strong>
      <Link
        to={uri}
        rel="noreferrer"
        onClick={() => {
          track({ action: "New article banner clicked" });
          setIsClicked(true);
        }}
      >
        &nbsp;{title} &#8250;
      </Link>
    </div>
  );
};

export default NewArticleBanner;
